import React from 'react'
import ReactGA from 'react-ga'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { getActiveTheme } from 'utils/theme'

import Container from 'components/Container'
import SEO from 'components/Seo'
import Posts from 'components/Posts'

const trackBlogClick = () => {
  ReactGA.event({
    category: '404',
    action: 'click',
    label: `404 - Go to blog`,
  })
}

const NotFoundPage = () => {
  return (
    <Container>
      <SEO title='Página não encontrada' />
      <section>
        <article>
          <h1>
            Não tem nada aqui...
            <span className='small' role='img' aria-label='Emoji assustado'>
              😱
            </span>
          </h1>

          <p className='support-title'>
            Mas não se preocupe!
            <span className='small' role='img' aria-label='Emoji feliz'>
              😄
            </span>
          </p>

          <ul>
            <li>
              Da uma olhada{' '}
              <AniLink
                cover
                bg={getActiveTheme()}
                direction='down'
                duration={1}
                to='/blog/'
                onClick={() => trackBlogClick()}
              >
                ler meu blog
              </AniLink>
              ?
            </li>
          </ul>
        </article>

        <Posts />
      </section>
    </Container>
  )
}

export default NotFoundPage
